<template>
  <Dialog  v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      v-if="visible"
      ref="TableLayout"
      :columns="getColumns"
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/serve/serve-page?`"
      :export-option="
        isSubbranch
          ? {
              fileName: '预警列表',
              url: `/${$businessISG}/monitor/home/warn-export`,
            }
          : {}
      "
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <template v-if="isSubbranch">
          <QueryLayout lable="类型">
            <Select
              v-model="queryParam.warnType"
              :options="warnType"
              :replace-fields="{
                lable: 'msg',
                value: 'code',
              }"
              placeholder="请选择类型"
            ></Select>
          </QueryLayout>
          <QueryLayout lable="状态">
            <Select
              v-model="queryParam.readFlag"
              :options="readFlag"
              :replace-fields="{
                lable: 'msg',
                value: 'code',
              }"
              placeholder="请选择状态"
            ></Select>
          </QueryLayout>
        </template>
        <template v-else>
          <QueryLayout lable="机构名称">
            <SelectTree
              v-model="queryParam.organizationUUID"
              :options="organizationTree"
              :replace-fields="{
                itemText: 'name',
                itemKey: 'uuid',
                itemChildren: 'children',
              }"
              placeholder="请选择机构名称"
            ></SelectTree>
          </QueryLayout>
        </template>
        <QueryLayout lable="预警时间">
          <DatePicker v-model="queryParam.time" range></DatePicker>
        </QueryLayout>
      </template>
      <template slot="table-item-times" slot-scope="{ row }">
        第{{ row.times ? row.times : 0 }}次
      </template>
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
        @click="
          $refs.TaskInfoDialog.openDialog({ row })
        "
      >
        <div style="color: #25c4ff">查看</div>
      </div>
    </TableLayout>
    <EarlyWarningHandleInfoDialog
      ref="TaskInfoDialog"
      @success="$refs.TableLayout && $refs.TableLayout.getData()"
    ></EarlyWarningHandleInfoDialog>
    <!--    <TaskInfoDialog ref="TaskInfoDialog"-->
    <!--                    @success="$refs.TableLayout && $refs.TableLayout.getData()"></TaskInfoDialog>-->
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import Select from '@/views/screen/components/Select.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
import EarlyWarningHandleInfoDialog from "@/views/screen/earlyWarning/modules/EarlyWarningHandleInfoDialog";
export default {
  //组件注册
  components: {
    Dialog,
    TableLayout,
    QueryLayout,
    Select,
    SelectTree,
    DatePicker,
    EarlyWarningHandleInfoDialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '服务质检预警一览',
        },
      },
    };
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
      organizationTree: (state) => state.screenStore.organizationTree,
      warnType: (state) =>
        state.screenStore.commondict['WarnTypeEnum']
          ? state.screenStore.commondict['WarnTypeEnum']
          : [],
      readFlag: (state) =>
        state.screenStore.commondict['WarnReadFlagEnum']
          ? state.screenStore.commondict['WarnReadFlagEnum']
          : [],
    }),
    getColumns() {
      return [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
          visible: true,
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
          visible: !this.isSubbranch,
        },
        {
          text: '位置',
          value: 'areaText',
          sortable: false,
          align: 'center',
          visible: !this.isSubbranch,
        },
        {
          text: '预警内容',
          value: 'cameraName',
          sortable: false,
          align: 'center',
          visible: !this.isSubbranch,
        },
        {
          text: '监督类型',
          value: 'analysisItemText',
          sortable: false,
          align: 'center',
          visible: !this.isSubbranch,
        },
        // {
        //   text: '已催促次数',
        //   value: 'urgeTimes',
        //   sortable: false,
        //   align: 'center',
        //   visible: true,
        // },
        {
          text: '状态',
          value: 'statusText',
          sortable: false,
          align: 'center',
          visible: !this.isSubbranch,
        },
        // {
        //   text: '是否处罚',
        //   value: 'punishFlagText',
        //   sortable: false,
        //   align: 'center',
        //   visible: !this.isSubbranch,
        // },
        // {
        //   text: '类型',
        //   value: 'typeText',
        //   sortable: false,
        //   align: 'center',
        //   visible: this.isSubbranch,
        // },
        {
          text: '查看状态',
          value: 'reviewStatusText',
          sortable: false,
          align: 'center',
          visible: this.isSubbranch,
        },
        // {
        //   text: '内容',
        //   value: 'remark',
        //   sortable: false,
        //   align: 'center',
        //   visible: this.isSubbranch,
        // },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
          visible: true,
        },
      ].filter((e) => e.visible);
    },
  },
  //属性监听
  watch: {
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
        status: '02', handleFlag: '01'
      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.time = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['time'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      if (this.dialogOptions.query)
        query = Object.assign(query, this.dialogOptions.query);
      return query;
    },
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
